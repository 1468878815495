<template>
    <section class="my-0 section-shaped" :class="{'section': desktop}">
        <card class="border-0">
            <div class="row justify-content-center">
                <div class="col-lg-4">
                    <template>
                        <card type="secondary" shadow
                              header-classes="bg-white pb-5"
                              body-classes="px-lg-5 py-lg-5"
                              class="border-0">
                            <template>
                                <base-alert v-if="alert.new" :type="alert.type">
                                    <span class="alert-inner--text">{{alert.message}}</span>
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alert.new = false">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </base-alert>
                                <form role="form">
                                    <b-form-group>
                                        <b-form-radio-group v-model="model.as" style="font-weight: 700;">
                                            <b-form-radio value="requestor">{{$t('requestor')}}</b-form-radio>
                                            <b-form-radio value="realtor">{{$t('realtor')}}</b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>

                                    <base-input alternative
                                                v-model="model.email"
                                                class="mb-3"
                                                placeholder="E-mail"
                                                addon-left-icon="ni ni-email-83">
                                    </base-input>

                                    <base-input alternative
                                                v-model="model.password"
                                                type="password"
                                                placeholder="Password"
                                                addon-left-icon="ni ni-lock-circle-open">
                                    </base-input>
                                    <base-checkbox v-model="model.remember" style="font-weight: 700;">
                                        {{$t('login.remember')}}
                                    </base-checkbox>
                                    <div class="text-center">
                                        <base-button type="primary" class="my-4" @click="submitModel()">{{$t('login.login')}}</base-button>
                                    </div>
                                </form>
                            </template>
                        </card>
                    </template>
                    <div class="row mt-3">
                        <div class="col-6" style="font-weight: bold !important">
                            <router-link to="/reset" class="text-light">
                                {{$t('login.reset')}}
                            </router-link>
                        </div>
                        <div class="col-6" style="font-weight: bold !important">
                            <router-link to="/register" class="text-light">
                                {{$t('login.register')}}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </card>
    </section>
</template>
<script>
    import { BFormGroup, BFormRadio, BFormRadioGroup } from 'bootstrap-vue';
    import $ from 'jquery';

    export default {
        components: {
            BFormRadio,
            BFormGroup,
            BFormRadioGroup,
        },

        data() {
            return {
                model: {
                    as: 'requestor',
                    email: '',
                    password: '',
                    remember: false,
                    type: 'manual'
                },
                alert: {
                    new: false,
                    message: '',
                    type: 'warning',
                },
                desktop: screen.width > 700,
            }
        },

        methods: {
            submitModel() {
                var alert;
                var pushAlert = this.pushAlert;
                var as = this.$t('warning.login.as.' + this.model.as);
                var message = this.$t('warning.login.match', { as: as });
                var store = this.$store;
                var router = this.$router;
                var gtag = this.$gtag;

                if (this.model.password.length < 6) {
                    alert = true;
                    message = this.$t('warning.login.password')
                }

                if (this.model.email === '') {
                    alert = true;
                    message = this.$t('warning.login.email')
                } else if (!(this.model.email.includes('@') && this.model.email.includes('.') && this.model.email.length > 5)) {
                    alert = true;
                    message = this.$t('warning.login.email')
                }

                if (alert) {
                    pushAlert(message);
                } else {
                    this.model.email = this.model.email.replace(/^\s+|\s+$/g, '');
                    $.ajax({
                        url: 'https://api.immobl.com/login',
                        headers: {
                            "X-Frame-Options": "SAMEORIGIN",
                            "Content-Type": "application/json;charset=UTF-8"
                        },
                        type: 'POST',
                        xhrFields: {
                            withCredentials: true
                        },
                        crossDomain: true,
                        sameSite: true,
                        data: JSON.stringify({ data: this.model }),
                        success: function (response) {
                            if (response.success) {
                                updateStorage(response.user_data);
                            } else if (response.logged_in) {
                                router.push('/dashboard')
                            } else {
                                pushAlert(message);
                            }
                        },
                        //error: function (err) {
                        //    //console.log(err);
                        //},
                    });
                }

                function updateStorage(data) {
                    var redirect = store.state.redirect.slice(0);
                    //if (data.type == 'realtor' && redirect == 'dashboard') {
                    //    redirect = 'reply';
                    //}

                    store.replaceState({...store.state, ...data });
                    store.dispatch('updateUserId', data._id);
                    store.dispatch('updateLoginStatus', true);
                    store.dispatch('updateType', data.type);

                    router.push(redirect);

                    gtag.set({ 'user_id': data._id });
                    gtag.event('login', { method: 'page' })
                }                
            },

            pushAlert(message, type=null, from_server=null, route=null) {
                if (from_server) {
                    this.alert.message = this.$t(message);
                } else {
                    this.alert.message = message;
                }

                if (!type) {
                    type = 'warning';
                }

                if (route) {
                    this.$store.state.alert.message = message;
                    this.$store.state.alert.new = true;
                    this.$store.state.alert.type = type;
                    this.$router.push(route);
                } else {
                    this.alert.new = true;
                    this.alert.type = type;
                    $("html, body").animate({ scrollTop: 0 }, 1000);
                }
            },
        },        

        mounted() {
            if ('email' in this.$route.query && 'password' in this.$route.query) {
                if (this.$route.query.redirect != undefined) {
                    this.$store.state.redirect = this.$route.query.redirect;
                }

                this.model = { ...this.model, ...this.$route.query };
                this.submitModel()
            }

            if (this.$store.state.alert.new) {
                this.pushAlert(this.$store.state.alert.message, this.$store.state.alert.type, true)
                this.$store.state.alert.new = false;
            }
        }
    };
</script>
<style>
</style>
